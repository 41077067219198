@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lora:wght@700&family=PT+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-blue: 7 86 158;
    --color-light-blue: 53 191 232;
    --color-orange: 255 140 30;
    --color-light-grey: 230 238 245;
  }
  
  body {
    @apply font-body text-blue bg-light-grey;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-headline text-blue;
  }

  .subhead, blockquote {
    @apply font-subhead font-bold;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
}

html {
  scroll-behavior: smooth;
}